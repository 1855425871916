import { FC, ReactNode, Suspense, useEffect } from 'react';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { theme } from '../theme';
import { useTranslation } from 'react-i18next';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { HelmetProvider } from 'react-helmet-async';
import { Analytics } from '@vercel/analytics/react';
import { SpeedInsights } from '@vercel/speed-insights/react';
import { ToastMessageProvider } from '@context';
import { disabledFeatures } from './features';

const mode = import.meta.env.VITE_MODE ?? 'not-found';
const dir = {
  ar: 'rtl',
  en: 'ltr',
};
export const AllProviders: FC<{ children: ReactNode }> = ({ children }) => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: false,
        refetchOnWindowFocus: false,
      },
    },
  });

  const {
    i18n: { language, changeLanguage },
  } = useTranslation();

  const direction = dir[(language as 'ar' | 'en') ?? 'ar'];

  useEffect(() => {
    document.body.dir = direction;
  }, [language]);

  const Ribbon = () => (
    <>
      {mode === 'DEV' && (
        <div
          className={
            'text-white text-xl py-1 absolute w-[400px] flex items-center ' +
            'justify-center bg-primary top-2 -right-40 rotate-[45deg] z-[2000] pointer-events-none opacity-75'
          }
        >
          Demo
        </div>
      )}
    </>
  );

  const Wrapper: FC<{ children: ReactNode }> = ({ children }) => {
    return <>{children}</>;
  };

  return (
    <Suspense fallback={<></>}>
      <HelmetProvider>
        <Wrapper>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <ThemeProvider theme={{ ...theme, direction }}>
              <ToastMessageProvider>
                <QueryClientProvider client={queryClient}>
                  <CssBaseline />
                  {<Ribbon />}
                  {children}
                  <Analytics />
                  <SpeedInsights />
                </QueryClientProvider>
              </ToastMessageProvider>
            </ThemeProvider>
          </LocalizationProvider>
        </Wrapper>
      </HelmetProvider>
    </Suspense>
  );
};
