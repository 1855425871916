import { format, differenceInDays } from 'date-fns';
import { enUS } from 'date-fns/locale';
import {
    Box, Table,
    TableCell,
    TableHead,
    TableCellProps,
    styled,
    TableRowProps,
    TableRow,
    TableBody,
    Collapse,
    Slide
} from '@mui/material';
import { useState } from 'react';
import { Calendar } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import useBreakPoinst from '@hooks/useBreakPoinst';


const MockedResponse = {
    "statusCode": 200,
    "isSuccess": true,
    "errorMessages": [],
    "result": [
        {
            "success": true,
            "propertyName": "الياسمين",
            "dates": [
                {
                    "date": "2024-02-01",
                    "checkIn": "2024-01-28",
                    "checkOut": "2024-02-02",
                    "custom_price": "0",
                    "price": "1250",
                    "is_reserved": "1",
                    "is_busy": "0",
                    "reservation_id": "2985004",
                    "user_name": "ريم",
                    "clientNumber": "يظهر رقم الضيف قبل تاريخ الدخول ب 24 ساعة",
                    "type": null,
                    "status": null,
                    "flag": "GR"
                },
                {
                    "date": "2024-02-01",
                    "checkIn": "2024-03-28",
                    "checkOut": "2024-03-28",
                    "custom_price": "0",
                    "price": "1250",
                    "is_reserved": "1",
                    "is_busy": "0",
                    "reservation_id": "2985004",
                    "user_name": "ريم",
                    "clientNumber": "يظهر رقم الضيف قبل تاريخ الدخول ب 24 ساعة",
                    "type": null,
                    "status": null,
                    "flag": "GR"
                },
                {
                    "date": "2024-02-01",
                    "checkIn": "2024-04-28",
                    "checkOut": "2024-05-01",
                    "custom_price": "0",
                    "price": "1250",
                    "is_reserved": "1",
                    "is_busy": "0",
                    "reservation_id": "2985004",
                    "user_name": "ريم",
                    "clientNumber": "يظهر رقم الضيف قبل تاريخ الدخول ب 24 ساعة",
                    "type": null,
                    "status": null,
                    "flag": "GR"
                },
                {
                    "date": "2024-05-05",
                    "checkIn": "2024-05-07",
                    "checkOut": "2024-02-02",
                    "custom_price": "0",
                    "price": "1250",
                    "is_reserved": "1",
                    "is_busy": "0",
                    "reservation_id": "2985004",
                    "user_name": "ريم",
                    "clientNumber": "يظهر رقم الضيف قبل تاريخ الدخول ب 24 ساعة",
                    "type": null,
                    "status": null,
                    "flag": "GR"
                },




            ]
        },
        {
            "success": true,
            "propertyName": "luxury",
            "dates": [
                {
                    "date": "2024-02-01",
                    "checkIn": "2024-01-28",
                    "checkOut": "2024-02-02",
                    "custom_price": "0",
                    "price": "1250",
                    "is_reserved": "1",
                    "is_busy": "0",
                    "reservation_id": "2985004",
                    "user_name": "ريم",
                    "clientNumber": "يظهر رقم الضيف قبل تاريخ الدخول ب 24 ساعة",
                    "type": null,
                    "status": null,
                    "flag": "GR"
                },
                {
                    "date": "2024-02-02",
                    "checkIn": null,
                    "checkOut": null,
                    "custom_price": "0",
                    "price": "1250",
                    "is_reserved": "0",
                    "is_busy": "1",
                    "reservation_id": "",
                    "user_name": "",
                    "clientNumber": null,
                    "type": null,
                    "status": null,
                    "flag": null
                },
                {
                    "date": "2024-02-03",
                    "checkIn": null,
                    "checkOut": null,
                    "custom_price": "0",
                    "price": "1250",
                    "is_reserved": "0",
                    "is_busy": "1",
                    "reservation_id": "",
                    "user_name": "",
                    "clientNumber": null,
                    "type": null,
                    "status": null,
                    "flag": null
                },
                {
                    "date": "2024-02-04",
                    "checkIn": null,
                    "checkOut": null,
                    "custom_price": "0",
                    "price": "1250",
                    "is_reserved": "0",
                    "is_busy": "0",
                    "reservation_id": "",
                    "user_name": "",
                    "clientNumber": null,
                    "type": null,
                    "status": null,
                    "flag": null
                },
                {
                    "date": "2024-02-05",
                    "checkIn": null,
                    "checkOut": null,
                    "custom_price": "0",
                    "price": "1250",
                    "is_reserved": "0",
                    "is_busy": "0",
                    "reservation_id": "",
                    "user_name": "",
                    "clientNumber": null,
                    "type": null,
                    "status": null,
                    "flag": null
                },
                {
                    "date": "2024-02-06",
                    "checkIn": null,
                    "checkOut": null,
                    "custom_price": "0",
                    "price": "1250",
                    "is_reserved": "0",
                    "is_busy": "0",
                    "reservation_id": "",
                    "user_name": "",
                    "clientNumber": null,
                    "type": null,
                    "status": null,
                    "flag": null
                },
                {
                    "date": "2024-02-07",
                    "checkIn": null,
                    "checkOut": null,
                    "custom_price": "0",
                    "price": "1250",
                    "is_reserved": "0",
                    "is_busy": "0",
                    "reservation_id": "",
                    "user_name": "",
                    "clientNumber": null,
                    "type": null,
                    "status": null,
                    "flag": null
                },
                {
                    "date": "2024-02-08",
                    "checkIn": null,
                    "checkOut": null,
                    "custom_price": "0",
                    "price": "1250",
                    "is_reserved": "0",
                    "is_busy": "0",
                    "reservation_id": "",
                    "user_name": "",
                    "clientNumber": null,
                    "type": null,
                    "status": null,
                    "flag": null
                },
                {
                    "date": "2024-02-09",
                    "checkIn": null,
                    "checkOut": null,
                    "custom_price": "0",
                    "price": "1250",
                    "is_reserved": "0",
                    "is_busy": "0",
                    "reservation_id": "",
                    "user_name": "",
                    "clientNumber": null,
                    "type": null,
                    "status": null,
                    "flag": null
                },
                {
                    "date": "2024-02-10",
                    "checkIn": null,
                    "checkOut": null,
                    "custom_price": "0",
                    "price": "1250",
                    "is_reserved": "0",
                    "is_busy": "0",
                    "reservation_id": "",
                    "user_name": "",
                    "clientNumber": null,
                    "type": null,
                    "status": null,
                    "flag": null
                },
                {
                    "date": "2024-02-11",
                    "checkIn": null,
                    "checkOut": null,
                    "custom_price": "0",
                    "price": "1250",
                    "is_reserved": "0",
                    "is_busy": "0",
                    "reservation_id": "",
                    "user_name": "",
                    "clientNumber": null,
                    "type": null,
                    "status": null,
                    "flag": null
                },
                {
                    "date": "2024-02-12",
                    "checkIn": null,
                    "checkOut": null,
                    "custom_price": "0",
                    "price": "1250",
                    "is_reserved": "0",
                    "is_busy": "0",
                    "reservation_id": "",
                    "user_name": "",
                    "clientNumber": null,
                    "type": null,
                    "status": null,
                    "flag": null
                },
                {
                    "date": "2024-02-13",
                    "checkIn": null,
                    "checkOut": null,
                    "custom_price": "0",
                    "price": "1250",
                    "is_reserved": "0",
                    "is_busy": "0",
                    "reservation_id": "",
                    "user_name": "",
                    "clientNumber": null,
                    "type": null,
                    "status": null,
                    "flag": null
                },
                {
                    "date": "2024-02-14",
                    "checkIn": null,
                    "checkOut": null,
                    "custom_price": "0",
                    "price": "1250",
                    "is_reserved": "0",
                    "is_busy": "0",
                    "reservation_id": "",
                    "user_name": "",
                    "clientNumber": null,
                    "type": null,
                    "status": null,
                    "flag": null
                },
                {
                    "date": "2024-02-15",
                    "checkIn": null,
                    "checkOut": null,
                    "custom_price": "0",
                    "price": "1250",
                    "is_reserved": "0",
                    "is_busy": "0",
                    "reservation_id": "",
                    "user_name": "",
                    "clientNumber": null,
                    "type": null,
                    "status": null,
                    "flag": null
                },
                {
                    "date": "2024-02-16",
                    "checkIn": null,
                    "checkOut": null,
                    "custom_price": "0",
                    "price": "1250",
                    "is_reserved": "0",
                    "is_busy": "0",
                    "reservation_id": "",
                    "user_name": "",
                    "clientNumber": null,
                    "type": null,
                    "status": null,
                    "flag": null
                },
                {
                    "date": "2024-02-17",
                    "checkIn": null,
                    "checkOut": null,
                    "custom_price": "0",
                    "price": "1250",
                    "is_reserved": "0",
                    "is_busy": "0",
                    "reservation_id": "",
                    "user_name": "",
                    "clientNumber": null,
                    "type": null,
                    "status": null,
                    "flag": null
                },
                {
                    "date": "2024-02-18",
                    "checkIn": null,
                    "checkOut": null,
                    "custom_price": "0",
                    "price": "1250",
                    "is_reserved": "0",
                    "is_busy": "0",
                    "reservation_id": "",
                    "user_name": "",
                    "clientNumber": null,
                    "type": null,
                    "status": null,
                    "flag": null
                },
                {
                    "date": "2024-02-19",
                    "checkIn": null,
                    "checkOut": null,
                    "custom_price": "0",
                    "price": "1250",
                    "is_reserved": "0",
                    "is_busy": "0",
                    "reservation_id": "",
                    "user_name": "",
                    "clientNumber": null,
                    "type": null,
                    "status": null,
                    "flag": null
                },
                {
                    "date": "2024-02-20",
                    "checkIn": null,
                    "checkOut": null,
                    "custom_price": "0",
                    "price": "1250",
                    "is_reserved": "0",
                    "is_busy": "0",
                    "reservation_id": "",
                    "user_name": "",
                    "clientNumber": null,
                    "type": null,
                    "status": null,
                    "flag": null
                },
                {
                    "date": "2024-02-21",
                    "checkIn": null,
                    "checkOut": null,
                    "custom_price": "0",
                    "price": "1250",
                    "is_reserved": "0",
                    "is_busy": "0",
                    "reservation_id": "",
                    "user_name": "",
                    "clientNumber": null,
                    "type": null,
                    "status": null,
                    "flag": null
                },
                {
                    "date": "2024-02-22",
                    "checkIn": null,
                    "checkOut": null,
                    "custom_price": "0",
                    "price": "1250",
                    "is_reserved": "0",
                    "is_busy": "0",
                    "reservation_id": "",
                    "user_name": "",
                    "clientNumber": null,
                    "type": null,
                    "status": null,
                    "flag": null
                },
                {
                    "date": "2024-02-23",
                    "checkIn": null,
                    "checkOut": null,
                    "custom_price": "0",
                    "price": "1250",
                    "is_reserved": "0",
                    "is_busy": "0",
                    "reservation_id": "",
                    "user_name": "",
                    "clientNumber": null,
                    "type": null,
                    "status": null,
                    "flag": null
                },
                {
                    "date": "2024-02-24",
                    "checkIn": null,
                    "checkOut": null,
                    "custom_price": "0",
                    "price": "1250",
                    "is_reserved": "0",
                    "is_busy": "0",
                    "reservation_id": "",
                    "user_name": "",
                    "clientNumber": null,
                    "type": null,
                    "status": null,
                    "flag": null
                },
                {
                    "date": "2024-02-25",
                    "checkIn": null,
                    "checkOut": null,
                    "custom_price": "0",
                    "price": "1250",
                    "is_reserved": "0",
                    "is_busy": "0",
                    "reservation_id": "",
                    "user_name": "",
                    "clientNumber": null,
                    "type": null,
                    "status": null,
                    "flag": null
                },
                {
                    "date": "2024-02-26",
                    "checkIn": null,
                    "checkOut": null,
                    "custom_price": "0",
                    "price": "1250",
                    "is_reserved": "0",
                    "is_busy": "0",
                    "reservation_id": "",
                    "user_name": "",
                    "clientNumber": null,
                    "type": null,
                    "status": null,
                    "flag": null
                },
                {
                    "date": "2024-02-27",
                    "checkIn": null,
                    "checkOut": null,
                    "custom_price": "0",
                    "price": "1250",
                    "is_reserved": "0",
                    "is_busy": "0",
                    "reservation_id": "",
                    "user_name": "",
                    "clientNumber": null,
                    "type": null,
                    "status": null,
                    "flag": null
                },
                {
                    "date": "2024-02-28",
                    "checkIn": null,
                    "checkOut": null,
                    "custom_price": "0",
                    "price": "1250",
                    "is_reserved": "0",
                    "is_busy": "0",
                    "reservation_id": "",
                    "user_name": "",
                    "clientNumber": null,
                    "type": null,
                    "status": null,
                    "flag": null
                },
                {
                    "date": "2024-02-29",
                    "checkIn": null,
                    "checkOut": null,
                    "custom_price": "0",
                    "price": "1250",
                    "is_reserved": "0",
                    "is_busy": "0",
                    "reservation_id": "",
                    "user_name": "",
                    "clientNumber": null,
                    "type": null,
                    "status": null,
                    "flag": null
                }
            ]
        },
        {
            "success": true,
            "propertyName": "kkk",
            "dates": [
                {
                    "date": "2024-02-01",
                    "checkIn": null,
                    "checkOut": null,
                    "custom_price": "0",
                    "price": "1250",
                    "is_reserved": "0",
                    "is_busy": "1",
                    "reservation_id": "",
                    "user_name": "",
                    "clientNumber": null,
                    "type": null,
                    "status": null,
                    "flag": null
                },
                {
                    "date": "2024-02-02",
                    "checkIn": null,
                    "checkOut": null,
                    "custom_price": "0",
                    "price": "1250",
                    "is_reserved": "0",
                    "is_busy": "1",
                    "reservation_id": "",
                    "user_name": "",
                    "clientNumber": null,
                    "type": null,
                    "status": null,
                    "flag": null
                },
                {
                    "date": "2024-02-03",
                    "checkIn": null,
                    "checkOut": null,
                    "custom_price": "0",
                    "price": "1250",
                    "is_reserved": "0",
                    "is_busy": "1",
                    "reservation_id": "",
                    "user_name": "",
                    "clientNumber": null,
                    "type": null,
                    "status": null,
                    "flag": null
                },
                {
                    "date": "2024-02-04",
                    "checkIn": null,
                    "checkOut": null,
                    "custom_price": "0",
                    "price": "1250",
                    "is_reserved": "0",
                    "is_busy": "1",
                    "reservation_id": "",
                    "user_name": "",
                    "clientNumber": null,
                    "type": null,
                    "status": null,
                    "flag": null
                },
                {
                    "date": "2024-02-05",
                    "checkIn": null,
                    "checkOut": null,
                    "custom_price": "0",
                    "price": "1250",
                    "is_reserved": "0",
                    "is_busy": "1",
                    "reservation_id": "",
                    "user_name": "",
                    "clientNumber": null,
                    "type": null,
                    "status": null,
                    "flag": null
                },
                {
                    "date": "2024-02-06",
                    "checkIn": null,
                    "checkOut": null,
                    "custom_price": "0",
                    "price": "1250",
                    "is_reserved": "0",
                    "is_busy": "1",
                    "reservation_id": "",
                    "user_name": "",
                    "clientNumber": null,
                    "type": null,
                    "status": null,
                    "flag": null
                },
                {
                    "date": "2024-02-07",
                    "checkIn": null,
                    "checkOut": null,
                    "custom_price": "0",
                    "price": "1250",
                    "is_reserved": "0",
                    "is_busy": "1",
                    "reservation_id": "",
                    "user_name": "",
                    "clientNumber": null,
                    "type": null,
                    "status": null,
                    "flag": null
                },
                {
                    "date": "2024-02-08",
                    "checkIn": null,
                    "checkOut": null,
                    "custom_price": "0",
                    "price": "1250",
                    "is_reserved": "0",
                    "is_busy": "0",
                    "reservation_id": "",
                    "user_name": "",
                    "clientNumber": null,
                    "type": null,
                    "status": null,
                    "flag": null
                },
                {
                    "date": "2024-02-09",
                    "checkIn": null,
                    "checkOut": null,
                    "custom_price": "0",
                    "price": "1250",
                    "is_reserved": "0",
                    "is_busy": "0",
                    "reservation_id": "",
                    "user_name": "",
                    "clientNumber": null,
                    "type": null,
                    "status": null,
                    "flag": null
                },
                {
                    "date": "2024-02-10",
                    "checkIn": null,
                    "checkOut": null,
                    "custom_price": "0",
                    "price": "1250",
                    "is_reserved": "0",
                    "is_busy": "0",
                    "reservation_id": "",
                    "user_name": "",
                    "clientNumber": null,
                    "type": null,
                    "status": null,
                    "flag": null
                },
                {
                    "date": "2024-02-11",
                    "checkIn": null,
                    "checkOut": null,
                    "custom_price": "0",
                    "price": "1250",
                    "is_reserved": "0",
                    "is_busy": "0",
                    "reservation_id": "",
                    "user_name": "",
                    "clientNumber": null,
                    "type": null,
                    "status": null,
                    "flag": null
                },
                {
                    "date": "2024-02-12",
                    "checkIn": null,
                    "checkOut": null,
                    "custom_price": "0",
                    "price": "1250",
                    "is_reserved": "0",
                    "is_busy": "0",
                    "reservation_id": "",
                    "user_name": "",
                    "clientNumber": null,
                    "type": null,
                    "status": null,
                    "flag": null
                },
                {
                    "date": "2024-02-13",
                    "checkIn": null,
                    "checkOut": null,
                    "custom_price": "0",
                    "price": "1250",
                    "is_reserved": "0",
                    "is_busy": "0",
                    "reservation_id": "",
                    "user_name": "",
                    "clientNumber": null,
                    "type": null,
                    "status": null,
                    "flag": null
                },
                {
                    "date": "2024-02-14",
                    "checkIn": null,
                    "checkOut": null,
                    "custom_price": "0",
                    "price": "1250",
                    "is_reserved": "0",
                    "is_busy": "0",
                    "reservation_id": "",
                    "user_name": "",
                    "clientNumber": null,
                    "type": null,
                    "status": null,
                    "flag": null
                },
                {
                    "date": "2024-02-15",
                    "checkIn": null,
                    "checkOut": null,
                    "custom_price": "0",
                    "price": "1250",
                    "is_reserved": "0",
                    "is_busy": "0",
                    "reservation_id": "",
                    "user_name": "",
                    "clientNumber": null,
                    "type": null,
                    "status": null,
                    "flag": null
                },
                {
                    "date": "2024-02-16",
                    "checkIn": null,
                    "checkOut": null,
                    "custom_price": "0",
                    "price": "1250",
                    "is_reserved": "0",
                    "is_busy": "0",
                    "reservation_id": "",
                    "user_name": "",
                    "clientNumber": null,
                    "type": null,
                    "status": null,
                    "flag": null
                },
                {
                    "date": "2024-02-17",
                    "checkIn": null,
                    "checkOut": null,
                    "custom_price": "0",
                    "price": "1250",
                    "is_reserved": "0",
                    "is_busy": "0",
                    "reservation_id": "",
                    "user_name": "",
                    "clientNumber": null,
                    "type": null,
                    "status": null,
                    "flag": null
                },
                {
                    "date": "2024-02-18",
                    "checkIn": null,
                    "checkOut": null,
                    "custom_price": "0",
                    "price": "1250",
                    "is_reserved": "0",
                    "is_busy": "0",
                    "reservation_id": "",
                    "user_name": "",
                    "clientNumber": null,
                    "type": null,
                    "status": null,
                    "flag": null
                },
                {
                    "date": "2024-02-19",
                    "checkIn": null,
                    "checkOut": null,
                    "custom_price": "0",
                    "price": "1250",
                    "is_reserved": "0",
                    "is_busy": "0",
                    "reservation_id": "",
                    "user_name": "",
                    "clientNumber": null,
                    "type": null,
                    "status": null,
                    "flag": null
                },
                {
                    "date": "2024-02-20",
                    "checkIn": null,
                    "checkOut": null,
                    "custom_price": "0",
                    "price": "1250",
                    "is_reserved": "0",
                    "is_busy": "0",
                    "reservation_id": "",
                    "user_name": "",
                    "clientNumber": null,
                    "type": null,
                    "status": null,
                    "flag": null
                },
                {
                    "date": "2024-02-21",
                    "checkIn": null,
                    "checkOut": null,
                    "custom_price": "0",
                    "price": "1250",
                    "is_reserved": "0",
                    "is_busy": "0",
                    "reservation_id": "",
                    "user_name": "",
                    "clientNumber": null,
                    "type": null,
                    "status": null,
                    "flag": null
                },
                {
                    "date": "2024-02-22",
                    "checkIn": null,
                    "checkOut": null,
                    "custom_price": "0",
                    "price": "1250",
                    "is_reserved": "0",
                    "is_busy": "0",
                    "reservation_id": "",
                    "user_name": "",
                    "clientNumber": null,
                    "type": null,
                    "status": null,
                    "flag": null
                },
                {
                    "date": "2024-02-23",
                    "checkIn": null,
                    "checkOut": null,
                    "custom_price": "0",
                    "price": "1250",
                    "is_reserved": "0",
                    "is_busy": "0",
                    "reservation_id": "",
                    "user_name": "",
                    "clientNumber": null,
                    "type": null,
                    "status": null,
                    "flag": null
                },
                {
                    "date": "2024-02-24",
                    "checkIn": null,
                    "checkOut": null,
                    "custom_price": "0",
                    "price": "1250",
                    "is_reserved": "0",
                    "is_busy": "0",
                    "reservation_id": "",
                    "user_name": "",
                    "clientNumber": null,
                    "type": null,
                    "status": null,
                    "flag": null
                },
                {
                    "date": "2024-02-25",
                    "checkIn": null,
                    "checkOut": null,
                    "custom_price": "0",
                    "price": "1250",
                    "is_reserved": "0",
                    "is_busy": "0",
                    "reservation_id": "",
                    "user_name": "",
                    "clientNumber": null,
                    "type": null,
                    "status": null,
                    "flag": null
                },
                {
                    "date": "2024-02-26",
                    "checkIn": null,
                    "checkOut": null,
                    "custom_price": "0",
                    "price": "1250",
                    "is_reserved": "0",
                    "is_busy": "0",
                    "reservation_id": "",
                    "user_name": "",
                    "clientNumber": null,
                    "type": null,
                    "status": null,
                    "flag": null
                },
                {
                    "date": "2024-02-27",
                    "checkIn": null,
                    "checkOut": null,
                    "custom_price": "0",
                    "price": "1250",
                    "is_reserved": "0",
                    "is_busy": "0",
                    "reservation_id": "",
                    "user_name": "",
                    "clientNumber": null,
                    "type": null,
                    "status": null,
                    "flag": null
                },
                {
                    "date": "2024-02-28",
                    "checkIn": null,
                    "checkOut": null,
                    "custom_price": "0",
                    "price": "1250",
                    "is_reserved": "0",
                    "is_busy": "0",
                    "reservation_id": "",
                    "user_name": "",
                    "clientNumber": null,
                    "type": null,
                    "status": null,
                    "flag": null
                },
                {
                    "date": "2024-02-29",
                    "checkIn": null,
                    "checkOut": null,
                    "custom_price": "0",
                    "price": "1250",
                    "is_reserved": "0",
                    "is_busy": "0",
                    "reservation_id": "",
                    "user_name": "",
                    "clientNumber": null,
                    "type": null,
                    "status": null,
                    "flag": null
                }
            ]
        },
        {
            "success": true,
            "propertyName": "jj",
            "dates": [
                {
                    "date": "2024-02-01",
                    "checkIn": null,
                    "checkOut": null,
                    "custom_price": "0",
                    "price": "1250",
                    "is_reserved": "0",
                    "is_busy": "1",
                    "reservation_id": "",
                    "user_name": "",
                    "clientNumber": null,
                    "type": null,
                    "status": null,
                    "flag": null
                },
                {
                    "date": "2024-02-02",
                    "checkIn": null,
                    "checkOut": null,
                    "custom_price": "0",
                    "price": "1250",
                    "is_reserved": "0",
                    "is_busy": "1",
                    "reservation_id": "",
                    "user_name": "",
                    "clientNumber": null,
                    "type": null,
                    "status": null,
                    "flag": null
                },
                {
                    "date": "2024-02-03",
                    "checkIn": null,
                    "checkOut": null,
                    "custom_price": "0",
                    "price": "1250",
                    "is_reserved": "0",
                    "is_busy": "1",
                    "reservation_id": "",
                    "user_name": "",
                    "clientNumber": null,
                    "type": null,
                    "status": null,
                    "flag": null
                },
                {
                    "date": "2024-02-04",
                    "checkIn": null,
                    "checkOut": null,
                    "custom_price": "0",
                    "price": "1250",
                    "is_reserved": "0",
                    "is_busy": "1",
                    "reservation_id": "",
                    "user_name": "",
                    "clientNumber": null,
                    "type": null,
                    "status": null,
                    "flag": null
                },
                {
                    "date": "2024-02-05",
                    "checkIn": null,
                    "checkOut": null,
                    "custom_price": "0",
                    "price": "1250",
                    "is_reserved": "0",
                    "is_busy": "1",
                    "reservation_id": "",
                    "user_name": "",
                    "clientNumber": null,
                    "type": null,
                    "status": null,
                    "flag": null
                },
                {
                    "date": "2024-02-06",
                    "checkIn": null,
                    "checkOut": null,
                    "custom_price": "0",
                    "price": "1250",
                    "is_reserved": "0",
                    "is_busy": "0",
                    "reservation_id": "",
                    "user_name": "",
                    "clientNumber": null,
                    "type": null,
                    "status": null,
                    "flag": null
                },
                {
                    "date": "2024-02-07",
                    "checkIn": null,
                    "checkOut": null,
                    "custom_price": "0",
                    "price": "1250",
                    "is_reserved": "0",
                    "is_busy": "0",
                    "reservation_id": "",
                    "user_name": "",
                    "clientNumber": null,
                    "type": null,
                    "status": null,
                    "flag": null
                },
                {
                    "date": "2024-02-08",
                    "checkIn": null,
                    "checkOut": null,
                    "custom_price": "0",
                    "price": "1250",
                    "is_reserved": "0",
                    "is_busy": "0",
                    "reservation_id": "",
                    "user_name": "",
                    "clientNumber": null,
                    "type": null,
                    "status": null,
                    "flag": null
                },
                {
                    "date": "2024-02-09",
                    "checkIn": null,
                    "checkOut": null,
                    "custom_price": "0",
                    "price": "1250",
                    "is_reserved": "0",
                    "is_busy": "0",
                    "reservation_id": "",
                    "user_name": "",
                    "clientNumber": null,
                    "type": null,
                    "status": null,
                    "flag": null
                },
                {
                    "date": "2024-02-10",
                    "checkIn": null,
                    "checkOut": null,
                    "custom_price": "0",
                    "price": "1250",
                    "is_reserved": "0",
                    "is_busy": "0",
                    "reservation_id": "",
                    "user_name": "",
                    "clientNumber": null,
                    "type": null,
                    "status": null,
                    "flag": null
                },
                {
                    "date": "2024-02-11",
                    "checkIn": null,
                    "checkOut": null,
                    "custom_price": "0",
                    "price": "1250",
                    "is_reserved": "0",
                    "is_busy": "0",
                    "reservation_id": "",
                    "user_name": "",
                    "clientNumber": null,
                    "type": null,
                    "status": null,
                    "flag": null
                },
                {
                    "date": "2024-02-12",
                    "checkIn": null,
                    "checkOut": null,
                    "custom_price": "0",
                    "price": "1250",
                    "is_reserved": "0",
                    "is_busy": "0",
                    "reservation_id": "",
                    "user_name": "",
                    "clientNumber": null,
                    "type": null,
                    "status": null,
                    "flag": null
                },
                {
                    "date": "2024-02-13",
                    "checkIn": null,
                    "checkOut": null,
                    "custom_price": "0",
                    "price": "1250",
                    "is_reserved": "0",
                    "is_busy": "0",
                    "reservation_id": "",
                    "user_name": "",
                    "clientNumber": null,
                    "type": null,
                    "status": null,
                    "flag": null
                },
                {
                    "date": "2024-02-14",
                    "checkIn": null,
                    "checkOut": null,
                    "custom_price": "0",
                    "price": "1250",
                    "is_reserved": "0",
                    "is_busy": "0",
                    "reservation_id": "",
                    "user_name": "",
                    "clientNumber": null,
                    "type": null,
                    "status": null,
                    "flag": null
                },
                {
                    "date": "2024-02-15",
                    "checkIn": null,
                    "checkOut": null,
                    "custom_price": "0",
                    "price": "1250",
                    "is_reserved": "0",
                    "is_busy": "0",
                    "reservation_id": "",
                    "user_name": "",
                    "clientNumber": null,
                    "type": null,
                    "status": null,
                    "flag": null
                },
                {
                    "date": "2024-02-16",
                    "checkIn": null,
                    "checkOut": null,
                    "custom_price": "0",
                    "price": "1250",
                    "is_reserved": "0",
                    "is_busy": "0",
                    "reservation_id": "",
                    "user_name": "",
                    "clientNumber": null,
                    "type": null,
                    "status": null,
                    "flag": null
                },
                {
                    "date": "2024-02-17",
                    "checkIn": null,
                    "checkOut": null,
                    "custom_price": "0",
                    "price": "1250",
                    "is_reserved": "0",
                    "is_busy": "0",
                    "reservation_id": "",
                    "user_name": "",
                    "clientNumber": null,
                    "type": null,
                    "status": null,
                    "flag": null
                },
                {
                    "date": "2024-02-18",
                    "checkIn": null,
                    "checkOut": null,
                    "custom_price": "0",
                    "price": "1250",
                    "is_reserved": "0",
                    "is_busy": "0",
                    "reservation_id": "",
                    "user_name": "",
                    "clientNumber": null,
                    "type": null,
                    "status": null,
                    "flag": null
                },
                {
                    "date": "2024-02-19",
                    "checkIn": null,
                    "checkOut": null,
                    "custom_price": "0",
                    "price": "1250",
                    "is_reserved": "0",
                    "is_busy": "0",
                    "reservation_id": "",
                    "user_name": "",
                    "clientNumber": null,
                    "type": null,
                    "status": null,
                    "flag": null
                },
                {
                    "date": "2024-02-20",
                    "checkIn": null,
                    "checkOut": null,
                    "custom_price": "0",
                    "price": "1250",
                    "is_reserved": "0",
                    "is_busy": "0",
                    "reservation_id": "",
                    "user_name": "",
                    "clientNumber": null,
                    "type": null,
                    "status": null,
                    "flag": null
                },
                {
                    "date": "2024-02-21",
                    "checkIn": null,
                    "checkOut": null,
                    "custom_price": "0",
                    "price": "1250",
                    "is_reserved": "0",
                    "is_busy": "0",
                    "reservation_id": "",
                    "user_name": "",
                    "clientNumber": null,
                    "type": null,
                    "status": null,
                    "flag": null
                },
                {
                    "date": "2024-02-22",
                    "checkIn": null,
                    "checkOut": null,
                    "custom_price": "0",
                    "price": "1250",
                    "is_reserved": "0",
                    "is_busy": "0",
                    "reservation_id": "",
                    "user_name": "",
                    "clientNumber": null,
                    "type": null,
                    "status": null,
                    "flag": null
                },
                {
                    "date": "2024-02-23",
                    "checkIn": null,
                    "checkOut": null,
                    "custom_price": "0",
                    "price": "1250",
                    "is_reserved": "0",
                    "is_busy": "0",
                    "reservation_id": "",
                    "user_name": "",
                    "clientNumber": null,
                    "type": null,
                    "status": null,
                    "flag": null
                },
                {
                    "date": "2024-02-24",
                    "checkIn": null,
                    "checkOut": null,
                    "custom_price": "0",
                    "price": "1250",
                    "is_reserved": "0",
                    "is_busy": "0",
                    "reservation_id": "",
                    "user_name": "",
                    "clientNumber": null,
                    "type": null,
                    "status": null,
                    "flag": null
                },
                {
                    "date": "2024-02-25",
                    "checkIn": null,
                    "checkOut": null,
                    "custom_price": "0",
                    "price": "1250",
                    "is_reserved": "0",
                    "is_busy": "0",
                    "reservation_id": "",
                    "user_name": "",
                    "clientNumber": null,
                    "type": null,
                    "status": null,
                    "flag": null
                },
                {
                    "date": "2024-02-26",
                    "checkIn": null,
                    "checkOut": null,
                    "custom_price": "0",
                    "price": "1250",
                    "is_reserved": "0",
                    "is_busy": "0",
                    "reservation_id": "",
                    "user_name": "",
                    "clientNumber": null,
                    "type": null,
                    "status": null,
                    "flag": null
                },
                {
                    "date": "2024-02-27",
                    "checkIn": null,
                    "checkOut": null,
                    "custom_price": "0",
                    "price": "1250",
                    "is_reserved": "0",
                    "is_busy": "0",
                    "reservation_id": "",
                    "user_name": "",
                    "clientNumber": null,
                    "type": null,
                    "status": null,
                    "flag": null
                },
                {
                    "date": "2024-02-28",
                    "checkIn": null,
                    "checkOut": null,
                    "custom_price": "0",
                    "price": "1250",
                    "is_reserved": "0",
                    "is_busy": "0",
                    "reservation_id": "",
                    "user_name": "",
                    "clientNumber": null,
                    "type": null,
                    "status": null,
                    "flag": null
                },
                {
                    "date": "2024-02-29",
                    "checkIn": null,
                    "checkOut": null,
                    "custom_price": "0",
                    "price": "1250",
                    "is_reserved": "0",
                    "is_busy": "0",
                    "reservation_id": "",
                    "user_name": "",
                    "clientNumber": null,
                    "type": null,
                    "status": null,
                    "flag": null
                }
            ]
        }
    ]
}

const TableCellItem = styled(TableCell)<TableCellProps>(({ theme }) => ({
    color: '#6B7280', fontWeight: 400, fontSize: '16px',

}));
const TableRowItem = styled(TableRow)<TableRowProps>(({ theme }) => ({
    height: '50px',

}));

const GetWeakFromTwoDates = (start = new Date()): Date[] => {
    let result: Date[] = []
    Array.from({ length: 7 }).map((_, i) => {
        let end = new Date(start);
        end.setDate(end.getDate() + i);
        result.push(end)
    })
    return result
}
const defaultCellWidth = 110
console.log('GetWeakFromTwoDates', GetWeakFromTwoDates())
const getBookedDates = (start = new Date, end = new Date) => {
    for (var arr = [], dt = new Date(start); dt <= new Date(end); dt.setDate(dt.getDate() + 1)) {
        arr.push(new Date(dt));
    }
    return arr;

}

const RenderCell = ({ title = '', bg = false }) => {
    return (
        <TableCellItem sx={{
            backgroundColor: bg ? '#EEEBFF' : 'white'
            , display: 'flex', alignItems: 'center', width: defaultCellWidth, borderLeftWidth: 1, borderColor: '#EEEBFF', borderLeftStyle: 'solid', textAlign: 'center', fontSize: 10, justifyContent: 'center'
        }}>
            <Box sx={{  display: 'flex', justifyContent: 'center', alignItems: 'center',fontSize:11,fontWeight:800 }}  >
                {title}
            </Box>
        </TableCellItem>
    )
}

const DemoListing = MockedResponse.result.map(booked => ({ ...booked, dates: booked.dates.filter(d => d.is_reserved == "1").map((d: any) => ({ ...d, days: getBookedDates(d.checkIn, d.checkOut) })) }))
const Calender = () => {
    const [startDate, setStartDate] = useState(new Date())
    const [showPicker, setShowPicker] = useState(false)
    const { isPhone } = useBreakPoinst()
    const RenderCalender = () => {
        return (
            <Box sx={{ height: '100vh', width: isPhone ? '70vw' : '100vw', overflowX: 'auto' }} >
                <Table aria-labelledby='tableTitle' size={'medium'}>

                    <TableHead sx={{ display: 'flex' }} >
                        <TableRowItem sx={{ display: 'flex' }} >
                            <RenderCell bg title='search'  />
                            {GetWeakFromTwoDates(startDate).map(v => <RenderCell bg title={`${format(v, "dd-MM-yyyy")} ${format(new Date(v), 'EEE', { locale: enUS })}`} />
                            )}
                        </TableRowItem>
                    </TableHead>
                    <TableBody>
                        <Box sx={{ mt: 1 }} />
                        {DemoListing.map(c => <TableRowItem style={{ backgroundColor: 'green', display: 'flex', height: '4rem', position: 'relative', width: defaultCellWidth * 8 }} >
                            <RenderCell title={c.propertyName} />
                            {
                                Array.from({ length: 7 }).map(c => <RenderCell title='' />)
                            }
                            {c.dates?.map(date => {
                                let currnetViewDates = date.days.map((v: Date) => format(v, "dd-MM-yyyy")).filter((curr: Date) => GetWeakFromTwoDates(startDate).map((v: Date) => format(v, "dd-MM-yyyy")).includes(curr + ''))
                                let firstDayReservation = new Date();
                                if (currnetViewDates.length > 0) {
                                    firstDayReservation = new Date(currnetViewDates[0].split('-')[2], currnetViewDates[0].split('-')[1] - 1, currnetViewDates[0].split('-')[0])
                                     console.log('firstDayReservation',firstDayReservation)
                                }
console.log('wanna',currnetViewDates)
                                const startFromIndx = currnetViewDates.length ? GetWeakFromTwoDates(startDate).findIndex(v => format(v, "dd-MM-yyyy") == format(firstDayReservation, "dd-MM-yyyy")) : 3
                                console.log('startFromIndx', startFromIndx)
                                return (
                                    <Box>

                                        {currnetViewDates.map((dd: Date) => <Slide
                                            mountOnEnter unmountOnExit
                                            in={currnetViewDates?.length > 0 ? true : false}
                                            direction='left'
                                        >
                                            <Box
                                                sx={{ position: 'absolute', right: defaultCellWidth + (startFromIndx * defaultCellWidth), top: 5, zIndex: 10000, background: '#EEEBFF',width: defaultCellWidth * currnetViewDates.length }}
                                            >
                                                {date.checkIn +'---'+ date.checkOut}
                                            </Box>
                                        </Slide>)}

                                    </Box>

                                )
                            })}



                        </TableRowItem>)}
                    </TableBody>




                </Table>
            </Box>

        )
    }

    return (
        <Box sx={{ width: '100vw' }} >
            <Box sx={{ paddingX: 10 }} >

                <Box
                    onClick={() => setShowPicker(!showPicker)}
                    sx={{ backgroundColor: '#EEEBFF', cursor: 'pointer', width: 50, height: 50, borderRadius: 10, margin: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                    Picker
                </Box>
                <Collapse in={showPicker}>

                    <Calendar
                        showMonthArrow={true}
                        date={startDate}
                        onChange={(d) => {
                            console.log('d', d)
                            setStartDate(new Date(d))
                        }}
                    />
                </Collapse>
                <h1>{startDate.toDateString()}</h1>
                <Box sx={{ display: 'flex', mb: 5 }} >
                    <Box onClick={() => {
                        let result = new Date(startDate);
                        result.setDate(result.getDate() + 7);
                        setStartDate(result)

                    }} sx={{ backgroundColor:'#EEEBFF', cursor: 'pointer', width: 50, height: 50, borderRadius: 10, margin: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }} >Next</Box>
                    <Box
                        onClick={() => {
                            let result = new Date(startDate);
                            result.setDate(result.getDate() - 7);
                            setStartDate(result)

                        }}
                        sx={{ backgroundColor:'#EEEBFF', cursor: 'pointer', width: 50, height: 50, borderRadius: 10, margin: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }} >Back</Box>
                </Box>


                <RenderCalender />

            </Box>
        </Box>
    )

}
export default Calender
