import { createBrowserRouter, Navigate, RouteObject } from 'react-router-dom';
import {
  Login,
  Register,
  ResetPassword,
  FinanceDetails,
  MyCalendar,
  Finance,
  LandingPage,
  ErrorPage,
  LocksManagement,
  LockDetails,
  PropertiesManagement,
  LocksListing,
  Settings,
  Messaging,
  AfterPayment,
  CalenderPoc,
  ManagePlan,
  Dashboard,
  PropertyProfile,
  ControlCenter,
  LeepIntro,
  LeepDetails
} from '@pages';
import { AuthGuard, AuthLayout, Layout, PublicLayout } from '@components';
import { ReservationInfo } from '../pages/ReservationInfo';

export const useRouterLinks = () => {
  const routerObjects: RouteObject[] = [
    {
      path: '/',
      element: (
        <LeepIntro />
      ),
    }, 
    {
      path: '/leep',
      element: (
       
          <LeepIntro />
       
      ),
    }, 
    {
      path: '/leep-details',
      element: (
       
          <LeepDetails />
       
      ),
    }, 
   

    
   
    
  ];
  const router = createBrowserRouter(routerObjects);

  return { router };
};
