import { lazy } from 'react';

const Terms = lazy(() => import('./Terms'));
const Register = lazy(() => import('./Register'));
const AfterPayment = lazy(() => import('./AfterPayment'));
const ResetPassword = lazy(() => import('./ResetPassword'));
const Login = lazy(() => import('./Login'));
const MyCalendar = lazy(() => import('./Calendar'));
const Finance = lazy(() => import('./Finance'));
const FinanceDetails = lazy(() => import('./FinanceDetails'));
const LandingPage = lazy(() => import('./LandingPage'));
const LocksManagement = lazy(() => import('./LocksManagement'));
const LockDetails = lazy(() => import('./LockDetails'));
const LocksListing = lazy(() => import('./LocksListing'));
const PropertiesManagement = lazy(() => import('./PropertiesManagement'));
const PropertyProfile = lazy(() => import('./PropertyProfile'));
const Settings = lazy(() => import('./Settings'));
const Dashboard = lazy(() => import('./Dashboard'));
const Messaging = lazy(() => import('./Messaging'));
const ManagePlan = lazy(() => import('./ManagePlan'));
const ControlCenter = lazy(() => import('./ControlCenter'));
const LeepIntro = lazy(() => import('./LeepIntro'));
const LeepDetails = lazy(() => import('./LeepDetails')); 


import ErrorPage from './ErrorPage';
import CalenderPoc from './CalenderPoc';

export {
  Terms,
  Register,
  AfterPayment,
  ResetPassword,
  Login,
  MyCalendar,
  Finance,
  FinanceDetails,
  LandingPage,
  ErrorPage,
  LocksManagement,
  LockDetails,
  LocksListing,
  PropertiesManagement,
  PropertyProfile,
  Settings,
  Messaging,
  CalenderPoc,
  ManagePlan,
  Dashboard,
  ControlCenter,
  LeepIntro,
  LeepDetails
  
};
